<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <div class="px-6 pt-8 pb-6">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="orderNumber"
                label="Order Number"
                dense
                outlined
                @keyup.enter="getBillingChargesByOrderNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="4" align="center">
              <v-btn
                :disabled="!orderNumber || loading"
                color="primary"
                @click="getBillingChargesByOrderNumber"
                >Get Charges By Order Number
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card outlined class="pa-2" v-if="billingCharges.length">
                <v-card-title
                  >Charges For {{ loadedOrderNumber }}</v-card-title
                >
                <v-data-table
                  :headers="billingChargesHeaders"
                  :items="billingCharges"
                  :items-per-page="5"
                  v-if="billingCharges.length"
                >
                </v-data-table>
              </v-card>
              <v-card
                outlined
                class="pa-2"
                v-if="billingCharges.length < 1 && loadedOrderNumber"
              >
                <v-card-title
                  >No charges found for {{ loadedOrderNumber }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import BillingSnap from "@/services/BillingSnap.js";

export default {
  data() {
    return {
      validForm: true,
      // Title
      title: "Billing Charges By Order Number",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      orderNumber: "",
      loadedOrderNumber: "",

      billingCharges: [],
      billingChargesHeaders: [
        { text: "Company Code", value: "company_code" },
        { text: "Charge Date", value: "charge_date" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "client_reference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billable_units" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "total_charge" },
        { text: "Charge Code", value: "charge_code" },
        { text: "Invoice Number", value: "invoice_number" },
        { text: "Committed", value: "committed" },
      ],
    };
  },

  methods: {
    getBillingChargesByOrderNumber() {
      this.loading = true;
      this.billingCharges = [];
      this.loadedOrderNumber = ''
      if (!this.orderNumber) return;
      BillingSnap.getBillingChargesByOrderNumber(this.orderNumber)
        .then((response) => {
          this.loading = false;
          this.billingCharges = response.data;
          this.loadedOrderNumber = this.orderNumber;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
  },
};
</script>
<style></style>
